<template>
    <div class="container page">
        <van-nav-bar title="打賞記錄" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="main">
            <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
                <van-empty v-if="list.length === 0" description="數據為空！" />
                <div v-else class="item_list" v-for="(v,key) in list" :key="key">
                    <div class="lottery_info">
                        <van-image class="cover" :src="v.ico">
                            <template v-slot:loading>
                                <van-loading type="spinner" />
                            </template>
                        </van-image>
                        <span class="period-number">{{v.expect}}</span>
                        <span class="period-number">{{v.name}}</span>
                    </div>
                    <div class="recent">
                        <div class="kuaisan-ball left">
                            <van-image class="res-img"
                                :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[0] + '.png' ">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <van-image class="res-img"
                                :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[1] + '.png' ">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <van-image class="res-img"
                                :src=" v.status === 0 ? 'img/lottery/open_num.gif' : 'img/lottery/shaizi/' + v.opencode[2] + '.png' ">
                                <template v-slot:loading>
                                    <van-loading type="spinner" />
                                </template>
                            </van-image>
                            <span
                                class="res-des middle">{{v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] + v.opencode[2]}}</span>
                            <span
                                class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? "玫瑰" : "氣球"}}</span>
                            <span
                                class="res-des middle">{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0 ? "雙" : "單"}}</span>
                        </div>
                    </div>
                    <div class="topInfo">
                        <span v-if="v.status === 1" style="color: #07c160">{{v.status_text}}</span>
                        <span v-else>{{v.status_text}}</span>
                        <!--span>下注金額：{{v.money}}</span-->
                    </div>
                    <!--   <div class="topInfo">
            <span v-if="v.is_win === 1" style="color: #07c160">{{v.win_text}}</span>
            <span v-else >{{v.win_text}}</span>
            <span>金额：{{v.profit}}</span>
          </div> -->
                    <!-- <div class="topInfo">
            <span>任务类型：</span>
            <span>{{v.type}}</span>
          </div> -->
                    <div class="time">
                        <!--span>投注号码：{{v.create_time}}</span-->
                        <span>打賞物件</span>
                        <span>打賞數量</span>
                        <span>打賞狀態</span>
                        <span>打賞時間</span>
                    </div>
                    <div class="time">
                        <span>{{getft(v.data[0].type)}}</span>
                        <span> {{v.data[0].money}}</span>
                        <span>{{get_win_text(v.data[0].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                    <div class="time" v-if="v.data.length>=2">
                        <span>{{getft(v.data[1].type)}}</span>
                        <span> {{v.data[1].money}}</span>
                        <span>{{get_win_text(v.data[1].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                    <div class="time" v-if="v.data.length>=3">
                        <span>{{getft(v.data[2].type)}}</span>
                        <span> {{v.data[2].money}}</span>
                        <span>{{get_win_text(v.data[2].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                    <div class="time" v-if="v.data.length>=4">
                        <span>{{getft(v.data[3].type)}}</span>
                        <span> {{v.data[3].money}}</span>
                        <span>{{get_win_text(v.data[3].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                    <div class="time" v-if="v.data.length>=5">
                        <span>{{getft(v.data[4].type)}}</span>
                        <span> {{v.data[4].money}}</span>
                        <span>{{get_win_text(v.data[4].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                    <div class="time" v-if="v.data.length>=6">
                        <span>{{getft(v.data[5].type)}}</span>
                        <span> {{v.data[5].money}}</span>
                        <span>{{get_win_text(v.data[5].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                    <div class="time" v-if="v.data.length>=7">
                        <span>{{getft(v.data[6].type)}}</span>
                        <span> {{v.data[6].money}}</span>
                        <span>{{get_win_text(v.data[6].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                    <div class="time" v-if="v.data.length>=8">
                        <span>{{getft(v.data[7].type)}}</span>
                        <span> {{v.data[7].money}}</span>
                        <span>{{get_win_text(v.data[7].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                    <div class="time" v-if="v.data.length>=9">
                        <span>{{getft(v.data[8].type)}}</span>
                        <span> {{v.data[8].money}}</span>
                        <span>{{get_win_text(v.data[8].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                    <div class="time" v-if="v.data.length>=10">
                        <span>{{getft(v.data[9].type)}}</span>
                        <span> {{v.data[9].money}}</span>
                        <span>{{get_win_text(v.data[9].win_text)}}</span>
                        <span>{{gettimedata(v.create_time)}}</span>
                    </div>
                </div>
            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isLoading: false,
                list: [],
                lot_type: {
                    dx: {},
                    ds: {},
                    data: {},
                    win_text: {}
                },
                isok: false,
                istime: 0,
                datalength: 0
            };
        },
        methods: {
            back() {
                return window.history.back();
            },
            onRefresh() {
                setTimeout(() => {
                    this.$toast('重繪成功');
                    this.isLoading = false;
                }, 500);
            },
            getUserGameList() {
                this.$http({
                    method: 'get',
                    url: 'user_get_game_list'
                }).then(res => {
                    console.log(res)
                    this.datalength = res.data.length
                    if (res.code === 200) {
                        this.list = res.data
                    } else if (res.code === 401) {
                        this.$toast(res.msg);

                    }
                })
            },
            getft(data) {
                if (data == '单') {
                    return "單"
                } else if (data == '双') {
                    return "雙"
                } else if(data == '大'){
                    return "玫瑰"
                }else if(data == '小'){
                    return "氣球"
                }
            },
            get_win_text(data) {
                if (data == '亏损') {
                    return "失敗"
                } else
                if (data == '盈利') {
                    return "成功"
                } else if(data == '結算中')  {
                    return '待審核'
                }

            },
            gettimedata(data) {
                const d = data.split(' ')

                return d[1]
            },

        },
        created() {
            if (!localStorage.getItem('token')) {
                this.$router.push({
                    path: '/Login'
                })
            } else {

                this.getUserGameList();

            }

        },
        beforeUnmount() {

        },
        updated() {
            setTimeout(() => {
                //this.$toast('重繪成功');
                this.isLoading = false;
            }, 500);
        }


    };
</script>

<style lang='less' scoped>
    @import "../../assets/css/base.css";

    ::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
        color: #000000;
        font-size: 35px;
    }

    ::v-deep .van-loading__text {
        color: #000000;
        font-size: 35px;
    }

    .container .main {
        position: relative;
        overflow: auto;
        background-color: #f2f2f5;
        height: 100%;
        padding: 0 10px;
    }

    .item_list {
        padding: 15px 15px;
        margin: 30px 10px;
        background: #fff;
        border-radius: 10px;
        line-height: 60px;
    }

    .item_list .topInfo span {
        flex: 1;
        font-size: 35px;
        font-weight: 700;
        color: #ff253f;
    }

    .time {

        display: flex;
        justify-content: space-evenly;
        align-items: center;
    }

    .item_list .time span {
        flex: 1;
        font-size: 25px;
        font-weight: 500;
        color: #000;
        text-align: center;

    }

    .item_list .topInfo span:last-child {
        float: right;
    }

    .item_list .desc span {
        font-size: 25px;
        font-weight: 700;
        color: #9b9b9b;
    }

    .item_list .cover {
        width: 60px;
        height: 60px;
        -o-object-fit: cover;
        object-fit: cover;
    }

    .item_list .period-number {
        margin-left: 50px;
        margin-right: 10px;
        height: 50px;
        line-height: 60px;
        font-size: 35px;
        font-weight: 700;
        color: #000;
    }

    .item_list .lottery_info {
        display: flex;
    }

    .recent {
        display: flex;
        align-items: center;
        height: 100px;
    }

    .kuaisan-ball .left {
        justify-content: flex-start;
    }

    .kuaisan-ball {
        flex: 1;
        display: flex;
        align-items: center;
    }

    .kuaisan-ball .res-img {
        width: 70px;
        height: 70px;
        margin-right: 30px;
    }

    .kuaisan-ball .res-des {
        font-weight: 700;
        text-align: center;
        color: #000;
    }

    .kuaisan-ball .res-des.middle {
        width: 15%;
        font-size: 35px;
    }
</style>
<template>
    <div class="container page">
        <van-nav-bar title="修改真實姓名" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
            <template #right>
                <span class="nav-right" @click="save()">確認修改</span>
            </template>
        </van-nav-bar>
        <van-cell-group>
            <van-field v-model="name" :readonly="userInfo.name !== ''" label="姓名" placeholder="請輸入真實姓名" />
        </van-cell-group>
        <p>為了您賬戶安全,真實姓名需要與綁定銀行卡姓名一致、請勿造成不必要損失</p>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                name: "",
                userInfo: {}
            };
        },
        methods: {
            back() {
                return window.history.back();
            },
            save() {
                if (this.userInfo.name) {
                    this.$toast("請勿重複設置！");
                    return true;
                }
                if (this.name === "" || this.name === null || this.name === undefined) {
                    this.$toast.fail("請輸入真實姓名！");
                    return false;
                }
                this.$http({
                    method: 'get',
                    data: {
                        name: this.name
                    },
                    url: 'user_set_name'
                }).then(res => {
                    if (res.code === 200) {
                        this.getUserInfo();
                        this.name = this.userInfo.name;
                        this.$toast(res.msg);
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },
            getUserInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_info'
                }).then(res => {
                    if (res.code === 200) {
                        this.userInfo = res.data;
                        this.name = res.data.name;
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            }
        },
        created() {
            if (!localStorage.getItem('token')) {
                this.$router.push({
                    path: '/Login'
                })
            } else {
                this.getUserInfo();
            }
        }
    };
</script>

<style lang='less' scoped>
    @import "../../assets/css/base.css";

    .van-cell {
        font-size: 35px;
        line-height: 80px;
    }

    .container p {
        padding: 0 15px;
        margin-top: 15px;
        font-size: 30px;
        color: #dc2037;
    }
</style>
<template>
    <div class="container page">
        <van-nav-bar title="收款卡信息" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div class="wrapper">
            <div class="add-card" @click="toBindCard(is_bind)">
                <van-icon name="plus" />
                <span>添加收款卡</span>
            </div>

            <div class="tips">提示:請綁定大型商業銀行,如需修改,請您聯繫在線客服</div>
            <div class="add-card" @click="toBindShuk(is_shuk)">
                <van-icon name="plus" />
                <span>添加轉數快</span>
            </div>
            <div class="tips">提示:轉數快大額、到賬速度快、推薦！</div>
            <div class="add-card" @click="toBindusdt(is_usdt)">
                <van-icon name="plus" />
                <span>添加USDT</span>
            </div>
            <div class="tips">提示:USDT大額、到賬速度快、推薦！</div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                is_bind: false,
                is_usdt: false,
                is_shuk: false,
                bankInfo: {},
                userInfo: {},
                type: 0,
                utype: '',

            };
        },
        methods: {
            back() {
                return window.history.back();
            },
            getUserBankInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_get_bank'
                }).then(res => {
                    if (res.code === 200) {
                        console.log(res)
                        this.is_bind = res.data.is_bank;
                        this.is_shuk = res.data.is_banshuk
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },
            getUsdtInfo() {
                this.$http({
                    method: 'get',
                    url: 'Usdtinfo'
                }).then(res => {

                    this.is_usdt = res.data.is_usdt


                })
            },
            getUserInfo() {
                this.$http({
                    method: 'get',
                    url: 'user_info'
                }).then(res => {
                    // console.log(res)
                    if (res.code === 200) {
                        this.userInfo = res.data;
                        this.name = res.data.name;
                    } else if (res.code === 401) {
                        this.$toast(res.msg);
                    }
                })
            },
            toBindusdt(data) {
                if (data === false) {
                    this.$router.push({
                        path: '/BindUSDT'
                    })
                } else {
                    this.$toast("已綁定了Usdt，需要更改請聯繫客服！");
                }
            },
            toBindShuk(data) {

                if (data === false) {
                    this.$router.push({
                        path: '/BindShuk'
                    })
                } else {
                    this.$toast("已綁定了數快，需要更改請聯繫客服！");
                }
            },
            toBindCard(data) {

                if (data === false) {
                    this.$router.push({
                        path: '/BindCard'
                    })
                } else {
                    this.$toast("已綁定了银行卡，需要更改請聯繫客服！");
                }
                /* if (!this.userInfo.name) {
                     this.$router.push("Setname");
                     this.$toast("請設置姓名後再綁定銀行卡！");
                     return true;
                 } else if (!this.userInfo.paypassword) {
                     this.$router.push("SetPayPassword");
                     this.$toast("請設置提現密碼後再綁定銀行卡！");
                     return true;
                 } else {*/


            }
        },
        created() {
            if (!localStorage.getItem('token')) {
                this.$router.push({
                    path: '/Login'
                })
            } else {
                this.getUserInfo();
                this.getUserBankInfo();
                this.getUsdtInfo();
            }
        }
    };
</script>

<style lang='less' scoped>
    @import "../../assets/css/base.css";

    .van-cell {
        font-size: 35px;
        line-height: 80px;
    }

    .container p {
        padding: 0 15px;
        margin-top: 15px;
        font-size: 30px;
        color: #dc2037;
    }

    .manage-card .wrapper {
        height: calc(100% - 10px);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    }

    .wrapper .add-card {
        margin: 1rem 0rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        background-color: #fff;
        height: 250px;
    }

    .wrapper .add-card span {
        margin-left: 10px;
        font-size: 30px;
    }

    .wrapper .tips {
        margin: 15px 15px;
        font-size: 25px;
        color: #fc0107;
        font-weight: 900;
    }

    .wrapper .bank .info {
        margin-left: 20px;
        flex: 1;
        color: #000;
    }

    .wrapper .bank .info .row-content {
        margin: 30px 0;
        line-height: 20px;
        font-size: 30px;
    }
</style>